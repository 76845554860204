
import {Vue, Options} from "vue-class-component";
import {requests} from "@/helpers/requests";
import {BlogsResp} from "@/responses/blog/blogs-resp";

import "vue3-carousel/dist/carousel.css";
import {Carousel, Slide, Pagination, Navigation} from "vue3-carousel";

@Options({
    name: "HomeTrends",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            blogs: [],
            settings: {
                itemsToShow: 1,
                snapAlign: "start",
                autoplay: "3000",
                wrapAround: "true",
            },
            breakpoints: {
                576: {
                    itemsToShow: 1,
                },
                768: {
                    itemsToShow: 2,
                },
                992: {
                    itemsToShow: 2,
                },
                1024: {
                    itemsToShow: 3,
                },
            },
        };
    },
    created() {
        requests.get("blogs").then((response: BlogsResp) => {
            if (response.status_code === 200) {
                this.blogs = response.data;
            }
        });
    },
    methods: {
        formatDate(event: Date) {
            return event.toLocaleDateString();
        },
        prev() {
            this.$refs.myCarousel.prev();
        },
        next() {
            this.$refs.myCarousel.next();
        },
    },
})
export default class HomeTrends extends Vue {
}
