<template>
  <div class="works">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 d-flex flex-column align-items-center">
          <h2>{{ $t("home.howItWorksTitle") }}</h2>
          <p>
            {{ $t("home.howItWorksBody") }}
          </p>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-4 col-sm-6"
          v-for="(work, index) in works"
          :key="index"
        >
          <div class="img">
            <p>{{ index + 1 }}</p>
          </div>
          <div class="work-card">
            <h3>{{ work.title }}</h3>
            <span>{{ work.description }}</span>
            <!-- <router-link to="">
              <div class="d-flex justify-content-between">
                <strong>{{ $t("buttons.readMore") }}</strong>
                <img
                  style="width: 35px"
                  src="@/assets/images/arrow-removebg-preview.png"
                  alt=""
                />
              </div>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  data() {
    return {
      works: [
        {
          title: this.$t("home.steps.title1"),
          description: this.$t("home.steps.description1"),
        },
        {
          title: this.$t("home.steps.title2"),
          description: this.$t("home.steps.description2"),
        },
        {
          title: this.$t("home.steps.title3"),
          description: this.$t("home.steps.description3"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.works {
  h2 {
    font-weight: 900;
  }
  > p {
    font-size: 18px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .img {
    background-image: url("@/assets/images/work.png");
  }

  .work-card {
    padding-top: 70px;
    padding-bottom: 30px;
    h3 {
      color: #272727;
      font-size: 21px;
      line-height: 31px;
      font-weight: 900;
    }
    span {
      color: #807e7e;
      font-weight: 700;
      font-size: 15px;
      line-height: 31px;
      text-align: justify;
      display: block;
      height: 95px;
    }
    a {
      display: block;
      margin-top: 30px;
      img {
        filter: grayscale(1);
      }
      &:hover {
        img {
          filter: unset;
        }
      }
      strong {
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .works {
    padding: 50px 0;
  }
}
</style>
