
import {Options, Vue} from "vue-class-component";
import {requests} from "@/helpers/requests";
import {ComponentResp} from "@/responses/component-resp";

@Options({
    name: "HomeHeader",
    data() {
        return {
            title: "",
            content: "",
        };
    },
    created() {
        const slug = "home-001"
        requests.getContent(slug).then((response: ComponentResp) => {
            if (response.status_code === 200) {
                this.title = response.component.page_title
                this.content = response.component.html_content
            }
        });
    }
})

export default class Header extends Vue {
}
