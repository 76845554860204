
import { Vue, Options } from "vue-class-component";
import { useI18n } from "vue-i18n";

@Options({
  name: "HomeTestimonials",
  created() {
    const { t } = useI18n();
    setTimeout(() => {
      if (this.$refs.carousel) {
        this.carouselWidth =
          this.$refs.carousel.clientWidth * this.testimonials.length;
      }
    }, 100);
    this.testimonials = [
      {
        text: t("home.testimonials.text1"),
        subText: t("home.testimonials.subText1"),
        name: t("home.testimonials.name1"),
        image: require("@/assets/images/testimonials/roses-home-2.png"),
      },
      {
        text: t("home.testimonials.text2"),
        subText: t("home.testimonials.subText2"),
        name: t("home.testimonials.name2"),
        image: require("@/assets/images/testimonials/roses-home.png"),
      },
    ];
  },
  data() {
    return {
      testimonials: [],
      page: 0,
      carouselWidth: null,
    };
  },
  methods: {
    move(inc: number) {
      this.page = Math.min(
        this.testimonials.length - 1,
        Math.max(this.page + inc, 0)
      );
    },
  },
})
export default class HomeTestimonials extends Vue {
  page = 0;
  carouselWidth: number | null = null;
  testimonials: Array<{ text: string; subText: string; name: string; image: string }> = [];

  move(inc: number) {
    this.page = Math.min(
      this.testimonials.length - 1,
      Math.max(this.page + inc, 0)
    );
  }
}
