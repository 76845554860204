import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueToast from 'vue-toast-notification';
import App from "./App.vue";
import 'vue-toast-notification/dist/theme-bootstrap.css';

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {} as any;
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale: string = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const app = createApp(App);

// Vuex Storage
import store from "./store";
app.use(store);

// Routing
import router from "./router";
app.use(router);

import axios from "axios";
import VueAxios from "vue-axios";
app.use(VueAxios, axios);

// Translations
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
app.use(i18n);

app.use(VueToast, {
  position: 'top-right',
  duration: 5000
});
app.mount("#app");
