
import { requests } from "@/helpers/requests";
import { FaqResp } from "@/responses/faq/faq-resp";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "FAQ",
  created() {
    this.fetchFaq();
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchFaq();
    },
  },
  data() {
    return {
      faqs: [],
    };
  },
  methods: {
    fetchFaq() {
      requests
        .get("faq?lang=" + this.$i18n.locale)
        .then((response: FaqResp) => {
          if (response.status_code == 200 && response.data) {
            this.faqs = response.data.slice(0, 5);
          }
        });
    },
  },
})
export default class FAQ extends Vue {}
