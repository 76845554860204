<template>
  <div>
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 mb-2 mb-md-0">
            <img class="logo" src="@/assets/images/footer-logo.png" alt="" />
            <p style="margin-top: 25px">
              {{ $t("footer.text") }}
            </p>
            <div>
              <router-link to=""
                ><img style="width: 38px" src="@/assets/images/visa.png" alt=""
              /></router-link>
              <router-link to=""
                ><img
                  style="width: 38px"
                  src="@/assets/images/mastercard.png"
                  alt=""
              /></router-link>
              <router-link to=""
                ><img
                  style="width: 38px"
                  src="@/assets/images/paypal.png"
                  alt=""
              /></router-link>
              <router-link to=""
                ><img
                  style="width: 38px"
                  src="@/assets/images/discover.png"
                  alt=""
              /></router-link>
              <router-link to=""
                ><img
                  style="width: 38px"
                  src="@/assets/images/amazon.png"
                  alt=""
              /></router-link>
            </div>
          </div>
          <div class="col-lg-2 my-2 my-md-0">
            <ul style="list-style: none" v-if="footer1">
              <h3>{{ footer1.menuName }}</h3>
              <li v-for="item in footer1.items" :key="item.menuitemId">
                <router-link :to="item.menuitemSlug">{{
                  item.menuitemTitle
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 my-2 my-md-0">
            <ul style="list-style: none" v-if="footer2">
              <h3>{{ footer2.menuName }}</h3>
              <li v-for="item in footer2.items" :key="item.menuitemId">
                <router-link :to="item.menuitemSlug">{{
                  item.menuitemTitle
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <h3>{{ $t("footer.newsletterTitle") }}</h3>
            <p style="margin-top: 17px">
              {{ $t("footer.newsLetterText") }}
            </p>
            <div class="input-group">
              <input
                v-model.trim.lazy="email" 
                type="text"
                class="form-control"
                :placeholder="$t('footer.newsLetterPlaceholder')"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <img
                class="input-group-text"
                id="basic-addon2"
                src="@/assets/images/mail.png"
                alt=""
              />
            </div>
            <div class="text-danger mt-1">
              <div v-if="!validation.email.required">{{ $t("newsLetter.error.email.required") }}</div>
              <div v-else-if="!validation.email.valid">{{ $t("newsLetter.error.email.valid") }}</div>
            </div>
            <button @click="onSubmit" class="btn btn-danger mt-3">
              Submit
            </button>
            <!-- <input type="email" placeholder="Email"> -->
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row justify-content-center m-0">
          <div v-if="$root.$i18n.locale !== 'nl'">
            <a
              :href="'https://dutchflowers.nl' + $route.fullPath"
              class="text-white footer-lang"
            >
              {{ $t("footer.menu3.netherlands") }}
            </a>
          </div>
          <div v-if="$root.$i18n.locale !== 'be'">
            <a
              :href="'https://dutchflowers.be' + $route.fullPath"
              class="text-white footer-lang"
            >
              {{ $t("footer.menu3.belgium") }}
            </a>
          </div>
          <div v-if="$root.$i18n.locale !== 'de'">
            <a
              :href="'https://dutchflowers.de' + $route.fullPath"
              class="text-white footer-lang"
            >
              {{ $t("footer.menu3.germany") }}
            </a>
          </div>
          <div v-if="$root.$i18n.locale !== 'fr'">
            <a
              :href="'https://dutchflowers.fr' + $route.fullPath"
              class="text-white footer-lang"
            >
              {{ $t("footer.menu3.france") }}
            </a>
          </div>
          <div v-if="$root.$i18n.locale !== 'com'">
            <a
              :href="'https://dutchflowers.com' + $route.fullPath"
              class="text-white footer-lang"
            >
              {{ $t("footer.menu3.england") }}
            </a>
          </div>
        </div>
        <div class="copyright-inner">
          <div class="row justify-content-center m-0">
            <div class="col-lg-6 d-flex justify-content-center">
              <p class="text-center">
                {{
                  $t("footer.bottomText", { year: new Date().getFullYear() })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import toastMixin from "@/Mixins/toastMixin";
import { requests } from "@/helpers/requests";

@Options({
  name: "Footer",
  mixins: [toastMixin],
  data() {
    return {
      footer1: null,
      footer2: null,
      validation: {
        error: false,
        email: {
          required: true,
          valid: true
        }
      }
    };
  },
  watch: {
    menus() {
      if (this.menus && this.menus["footer1"]) {
        this.footer1 = this.menus["footer1"];
      }
      if (this.menus && this.menus["footer2"]) {
        this.footer2 = this.menus["footer2"];
      }
    },
  },
  computed: {
    menus() {
      return this.$store.state.menus;
    },
  },
  methods: {
    validations () {
      this.validation.email.required = true;
      
      if(!this.email) {
        this.validation.email.required = false;
        this.validation.error = true;
      } else  if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
        this.validation.email.valid = false;
        this.validation.error = true;
      }
    },
    async onSubmit() {
      this.validations();
      if(this.validation.error) return;
      let url = "newsletter/insert";
      await requests.post(url, {"email": this.email}).then((response) => {
        if (response.success) {
          this.$displaySuccessToast(response.msg);
        }
      });
    },
  },
})
export default class Footer extends Vue {}
</script>

<style scoped lang="scss">
.footer {
  background: #111111;
  z-index: 11;

  .logo {
    width: 80px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 17px;
    font-weight: 700;
  }

  ul {
    li {
      font-size: 14px;
      padding: 3px 0;

      a {
        color: #ffffff;
      }
    }
  }

  .input-group {
    input {
      padding: 12px 0;
    }

    img {
      padding: 10px 0;
      width: 24px;
      right: 25px;
    }
  }
}

.copyright {
  background: #111111;

  .copyright-inner {
    border-top: 1px solid #dddddd;
    padding: 25px 0;

    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #ffffff;
      margin-bottom: 0;
    }
  }

  .footer-lang {
    padding: 0 20px;
  }
}
</style>
