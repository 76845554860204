<template>
  <div class="alertBox" v-bind:class="{ active: cartChanged }">
    <div class="inner">
      <i class="fa fa-check"></i>
      <span>Product has been added to your cart!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartAlert",
  data() {
    return {
      cartCount: 0,
      cartChanged: false,
    };
  },
  mounted() {
    if (
      this.$store.state &&
      this.$store.state.cart &&
      this.$store.state.cart.rows &&
      this.$store.state.cart.rows.length > 0
    ) {
      this.cartCount = this.$store.state.cart.rows.length;
    }
  },
  watch: {
    "$store.state.cart": function () {
      if (this.$store.state.cart && this.$store.state.cart.rows) {
        if (this.$store.state.cart.rows.length > this.cartCount) {
          this.cartChanged = true;
        }
      }
      setTimeout(() => {
        this.cartChanged = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.alertBox {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    .inner {
      &::after {
        width: 0%;
      }
    }
  }
  .inner {
    background: #f1652a;
    font-size: 15px;
    padding: 15px 25px;
    box-shadow: 2px 4px 10px 4px rgba(0, 0, 0, 0.1);
    color: #fff;
    &::after {
      content: "";
      display: block;
      transition: width 2s ease;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background: rgba(255, 255, 255, 0.4);
    }
    i {
      width: 28px;
    }
  }
}

@media screen and (max-width: 576px) {
  .alertBox {
    top: 10px;
    right: 10px;
    .inner {
      font-size: 14px;
      padding: 10px 15px;
      i {
        width: 24px;
      }
    }
  }
}
</style>
