
import LangSwitch from "@/components/LangSwitch.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "NavBar",
  created () {
    if(localStorage.getItem('token'))
      this.isLoggedin = true
  },
  components: {
    LangSwitch,
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    userFirstName() {
      return this.$store.state.userFirstName;
    },
    blockedDates() {
      return this.$store.state.blockedDates;
    },
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      showMenu: false,
      showPs: false,
    };
  },
  methods: {
    showMan() {
      this.showPs = !this.showPs;
    },
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.commit("setToken", '');
      localStorage.setItem('token', '');
      this.$router.push('/sign-in');
    }
  },
})
export default class NavBar extends Vue {}
