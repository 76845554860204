<template>
  <section id="intro">
    <div class="container">
      <div class="programs">
        <div class="row align-items-center">
          <div
            v-for="stat in stats"
            :key="stat.name"
            class="col-lg-3 col-md-6 mb-4 mb-lg-0"
          >
            <div class="stats-item">
              <div class="stats-icon">
                <img :src="stat.image" />
              </div>
              <div class="stats-text">
                <h4>{{ stat.name }}</h4>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <p class="text-center px-2 pre-wrap">
              {{ $t("home.program2") }}
            </p>
          </div>
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <p class="text-center px-2 pre-wrap">
              {{ $t("home.program3") }}
            </p>
          </div>
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <p class="text-center px-2 pre-wrap">
              {{ $t("home.program4") }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomePrograms",
  data() {
    return {
      stats: [
        {
          image: require("@/assets/images/stats/icon-1.png"),
          name: this.$t("home.program1"),
        },
        {
          image: require("@/assets/images/stats/icon-2.png"),
          name: this.$t("home.program2"),
        },
        {
          image: require("@/assets/images/stats/icon-3.png"),
          name: this.$t("home.program3"),
        },
        {
          image: require("@/assets/images/stats/icon-4.png"),
          name: this.$t("home.program4"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.programs {
  width: calc(100% + 100px);
  margin: 0 -50px;
  padding: 0 30px;
  top: -40px;
  .stats-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .stats-icon {
      flex: 0 0 auto;
      width: 56px;
      img {
        width: 100%;
      }
    }
    .stats-text {
      flex: 1;
      padding-left: 15px;
      h4 {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.03em;
        color: #272727;
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .programs {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 992px) {
  .programs {
    .row {
      padding: 15px 0 0 0;
    }
    .stats-item .stats-text h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .programs {
    .row {
      padding: 15px 0 0 0;
    }
  }
}
</style>
