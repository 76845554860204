import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeVideo = _resolveComponent("HomeVideo")!
  const _component_HomePrograms = _resolveComponent("HomePrograms")!
  const _component_HomeShop = _resolveComponent("HomeShop")!
  const _component_HomeHeader = _resolveComponent("HomeHeader")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_HomeTrends = _resolveComponent("HomeTrends")!
  const _component_HomeTestimonials = _resolveComponent("HomeTestimonials")!
  const _component_FAQ = _resolveComponent("FAQ")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeVideo),
    _createVNode(_component_HomePrograms),
    _createVNode(_component_HomeShop),
    _createVNode(_component_HomeHeader),
    _createVNode(_component_HowItWorks),
    _createVNode(_component_HomeTrends),
    _createVNode(_component_HomeTestimonials),
    _createVNode(_component_FAQ)
  ]))
}