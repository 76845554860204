export class Product {
    id = 0;
    sku = "";
    name = "";
    description = "";
    url_key = "";
    base_image = ""; // Of een specifiekere type indien bekend
    images: Array<string> = []; // Of een specifiekere type indien bekend
    is_active = false;
    is_new = false;
    is_featured = false;
    on_sale = false;
    is_saleable = false;
    is_wishlist = false;
    min_price = ""; // Geformatteerde prijs als string
    prices: Array<number> = []; // Of een specifiekere type indien bekend
    quantity = 0;
    price_html = "";
    ratings: any = {};
    reviews: any = {};

  static translateName(
    name: string,
    translations: Record<string, string>
  ) {
    name = name.toLowerCase();
    for (const key in translations) {
      name = name.replace(key, translations[key] as string);
    }
    return name;
  }
}
