import {createStore} from "vuex";
import {CartRow} from "@/models/cart";
import {Product} from "@/models/product";

export default createStore({
    state: {
        cart: {},
        blockedDates: [],
        menus: {},
        translations: {},
        token: localStorage.getItem('token'),
        userFirstName: localStorage.getItem('userFirstName')
    },
    getters: {
        cart(state) {
            return state.cart;
        }
    },
    mutations: {
        setCart(state, cart) {
            if (cart && cart.items) {
                cart.items.forEach((item: CartRow) => {
                    item.name = Product.translateName(
                        item.name,
                        state.translations
                    );
                });
            }
            state.cart = cart ? cart : {};


        },
        setBlockedDates(state, blockedDates) {
            state.blockedDates = blockedDates;
        },
        setMenus(state, menus) {
            state.menus = menus;
        },
        setTranslations(state, translations) {
            state.translations = translations;
        },
        setToken(state, token) {
            state.token = token;
        },
        setUserFirstName(state, firstName) {
            state.userFirstName = firstName;
        }
    },
    actions: {},
    modules: {},
});
