<template>
  <div class="home-about">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 d-flex justify-content-center">
          <h2>{{ $t("home.aboutUsTitle") }}</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-12 d-flex flex-column justify-content-center align-items-center"
        >
          <div class="showVideo" v-bind:class="{ active: showVid }">
            <video ref="videoRef" controls>
              <source src="@/assets/videos/about.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="imageArea" v-bind:class="{ hide: showVid }">
            <img class="w-100" src="@/assets/videos/thumb.jpg" alt="" />
            <div class="playBtn" @click="vidToggle">
              <img src="@/assets/images/icons/play.png" alt="" />
            </div>
          </div>

          <p class="px-2 mt-5">
            {{ $t("home.aboutUsText") }}
          </p>
          <button class="btn">{{ $t("buttons.learnMore") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAbout",
  data() {
    return {
      showVid: false,
    };
  },
  methods: {
    vidToggle() {
      this.showVid = !this.showVid;
      this.$refs.videoRef.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  h2 {
    border-bottom: 0;
    margin-bottom: 35px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 6px;
      border-radius: 10px;
      background: #21468b;
    }
  }
  .showVideo {
    display: none;
    &.active {
      display: block;
    }
    video {
      width: 100%;
    }
  }

  .imageArea {
    position: relative;
    &.hide {
      display: none;
    }
    .playBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  p {
    font-size: 18px;
    line-height: 31px;
    font-weight: 700;
  }
  button {
    padding: 12px 50px;
  }
}
</style>
