<template>
  <header class="home-hero" role="banner">
    <video
      ref="video"
      autoplay="autoplay"
      id="heroVideo"
      :muted="mute"
      playsinline
      poster="@/assets/videos/thumb.jpg"
    >
      <source src="@/assets/videos/about.mp4" type="video/mp4" />
    </video>
    <!-- <img
      alt="poster image mobile"
      class="poster"
      src="@/assets/videos/thumb.jpg"
    /> -->
  </header>
</template>

<script>
export default {
  name: "HeroVideo",
  data() {
    return {
      mute: true,
    };
  },
  methods: {
    muteVideo() {
      var vid = this.$refs.video;
      this.mute = !this.mute;
      vid.muted = this.mute;
    },
  },
  /* beforeMount() {
    this.muteVideo();
  }, */
};
</script>

<style scoped lang="scss">
.home-hero {
  video {
    width: 100%;
    height: 700px;
    object-fit: cover;
    vertical-align: middle;
  }
  /* .poster {
    width: 100%;
    height: 500px;
    object-fit: cover;
    vertical-align: middle;
    display: none;
  } */
}
@media only screen and (max-width: 576px) {
  .home-hero {
    video {
      height: 500px;
      /* display: none; */
    }
    /* .poster {
      display: block;
    } */
  }
}
</style>
