import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-md-12 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductItem = _resolveComponent("ProductItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.products.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("product.noneFound")), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-lg-3 col-md-6 col-sm-6 col-sm-6",
        key: product.sku
      }, [
        _createVNode(_component_ProductItem, {
          product: product,
          height: "250px"
        }, null, 8, ["product"])
      ]))
    }), 128))
  ]))
}