export default {
    methods: {
        $displayErrorToast(message) {
            this.$toast.open({
                message,
                type: 'error',
            });
        },
        $displaySuccessToast(message) {
            this.$toast.open({
                message,
                type: 'success',
            });
        }
    }
}