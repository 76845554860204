
import { Vue, Options } from "vue-class-component";
import ProductList from "../Product/ProductList.vue";

@Options({
  name: "HomeShop",
  components: {
    ProductList,
  },
})
export default class HomeShop extends Vue {}
