<template>
  <div class="langSwitch">
    <div class="main" @click="showMan">
      <div v-if="currentLocale" class="inner">
        <img :src="currentLocale.flag" />
        <!-- <span>{{ currentLocale.key }}</span> -->
      </div>
    </div>
    <div class="drop" v-bind:class="{ active: showPs }">
      <div
        v-for="locale in (key, locales)"
        :key="locale.key"
        @click="setLang(locale.key)"
        class="item"
        v-bind:class="[currentLocale.key === locale.key ? 'enable' : '']"
      >
        <img :src="locale.flag" />
        <span>{{ locale.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import nlSVG from "@/assets/images/flags/nl.svg";
import enSVG from "@/assets/images/flags/gb.svg";
import frSVG from "@/assets/images/flags/fr.svg";
import deSVG from "@/assets/images/flags/de.svg";

export default {
  name: "LangSwitch",
  data() {
    return {
      useDomains: true,
      showPs: false,
      currentLocale: [],
      locales: {
        nl: {
          key: "nl",
          name: "Netherlands",
          flag: nlSVG,
        },
        en: {
          key: "en",
          name: "English",
          flag: enSVG,
        },
        fr: {
          key: "fr",
          name: "French",
          flag: frSVG,
        },
        de: {
          key: "de",
          name: "German",
          flag: deSVG,
        },
      },
    };
  },
  mounted() {
    //this.currentLocale = this.$root.$i18n.locale;
    this.currentLocale = this.locales[this.$root.$i18n.locale];
  },
  methods: {
    showMan() {
      this.showPs = !this.showPs;
    },
    setLang(key) {
      if (this.useDomains) {
        if (key === "nl") {
          window.location.href =
            "https://dutchflowers.nl" + this.$route.fullPath;
        } else if (key === "en") {
          window.location.href =
            "https://dutchflowers.com" + this.$route.fullPath;
        } else if (key === "de") {
          window.location.href =
            "https://dutchflowers.de" + this.$route.fullPath;
        } else if (key === "fr") {
          window.location.href =
            "https://dutchflowers.fr" + this.$route.fullPath;
        }
        return;
      }
      this.showPs = false; // hide menu
      this.$i18n.locale = key;
      //this.currentLocale = key;
      this.currentLocale = this.locales[key];
    },
  },
};
</script>

<style lang="scss" scoped>
.langSwitch {
  position: relative;
  display: inline-block;

  .main {
    position: relative;
    background: rgb(240, 240, 240);
    border: 1px solid #ddd;
    padding: 3px 18px 3px 8px;
    border-radius: 4px;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px;
      border-color: rgba(0, 0, 0, 0) rgb(155, 155, 155) rgb(155, 155, 155)
        rgba(0, 0, 0, 0);
      transform: rotate(45deg);
      position: absolute;
      top: 12px;
      right: 6px;
    }

    img {
      width: 18px;
      vertical-align: middle;
    }

    span {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      color: #333;
      display: inline-block;
      padding-left: 8px;
    }
  }

  .drop {
    transition: 0.5s ease;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    border: 1px solid #ddd;
    margin-top: 5px;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .item {
      min-width: 150px;
      padding: 3px 15px;
      cursor: pointer;

      &.enable {
        background: #f3f3f3;
      }

      &:hover {
        background: rgb(230, 230, 230);
      }

      img {
        width: 18px;
        vertical-align: middle;
      }

      span {
        font-size: 14px;
        font-weight: 700;
        color: #333;
        display: inline-block;
        padding-left: 8px;
      }
    }
  }

  /* &:hover {
    .drop {
      opacity: 1;
      visibility: visible;
    }
  } */
}
</style>
