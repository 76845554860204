import axios from 'axios';

export const requests = {
    get,
    post,
    put,
    handleResponse,
    postRequest,
    deleteRequest,
    getContent
};

// const apiUrl = "https://dutchflowers.com";
// const apiUrl = "/api";

const apiUrl = process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_ADMIN_DOMAIN + "/api"
    : "/api";

// const apiUrl = process.env.VUE_APP_ADMIN_DOMAIN + "/api";
axios.defaults.withCredentials = true;


function get(url: string, headers = {}) {

    return axios.get(`${apiUrl}/${url}`, {headers, withCredentials: true, withXSRFToken: true})
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return handleHttpError(e);
        });
}

function post(url: string, payload: object, headers = {}) {
    // return dataRequest(url, payload, "POST", headers);
    return postRequest(url, payload, headers);
}


function put(url: string, payload: object, headers = {}) {
    return dataRequest(url, payload, "PUT", headers);
}

function dataRequest(url: string, payload: object, method: string, headers: object) {
    const csrfToken = document?.cookie?.split('; ')?.find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1] ?? '';
    if (csrfToken) {
        headers = {
            'X-XSRF-TOKEN': csrfToken,
            ...headers
        };
    }

    return axios.put(`${apiUrl}/${url}`, payload, {headers, withCredentials: true, withXSRFToken: true})
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return handleHttpError(e);
        });

}

function deleteRequest(url: string, payload: object, headers = {}) {
    const csrfToken = document?.cookie?.split('; ')?.find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1] ?? '';
    if (csrfToken) {
        headers = {
            'X-XSRF-TOKEN': csrfToken,
            ...headers
        };
    }


    return axios.delete(`${apiUrl}/${url}`, {params: payload, headers, withCredentials: true, withXSRFToken: true})
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return handleHttpError(e);
        });
}

function handleResponse(response: Response) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //Todo Show unauthorizes snackbar
            }
            if (response.status === 409) {
                return Promise.reject(data);
            }
            return Promise.reject(data);
        }

        return data;
    });
}

function postRequest(url: string, payload: object, headers: object) {
    const csrfToken = document?.cookie?.split('; ')?.find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1] ?? '';
    if (csrfToken) {
        headers = {
            'X-XSRF-TOKEN': csrfToken,
            ...headers
        };
    }

    return axios.post(`${apiUrl}/${url}`, payload, {headers, withCredentials: true, withXSRFToken: true})
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return handleHttpError(e);
        });
}


function handleHttpError(e: any) {
    if (e.response && e.response.data && e.response.data.message)
        return {error: true, msg: e.response.data.message, status: e.response.status, ...e.response.data};
    // not sure if some of the error response.data have message instead of errors parameter
    // errors will always be in "errors" (plural, my bad for some that were in "error")
    if (e.response && e.response.data && e.response.data.errors)
        return {error: true, msg: e.response.data.errors, status: e.response.status, ...e.response.data};

    if (e.response)
        return {error: true, msg: 'Something went wrong!', status: e.response.status, ...e.response.data};

    return {error: true, msg: 'Something went wrong!', status: 503};
}


function getContent(slug: string) {
    return get(`component/${slug}`);
}
